import {all, put, takeEvery} from 'redux-saga/effects'
import { APIClient } from '../../helpers/apiClient'
import {initiateGetDevices,setDevicesSaga} from './devicesSlice'
import { setDevices, getDevices } from '../../helpers/deviceUtils'

const api = new APIClient()

function* getSmsDevices(){
    let devices = yield getDevices()
    if(!devices){
        devices = yield api.get('/sms/devices')
        setDevices(devices)
    }
    yield put(setDevicesSaga(devices))
}

export function* watchDevicesProcesses(){
    yield takeEvery(initiateGetDevices().type, getSmsDevices)
}

function* devicesSaga(){
    yield all([watchDevicesProcesses])
}

export default devicesSaga
import { createSlice } from '@reduxjs/toolkit'
import {users, groups, contacts} from './demoUsers'

const initialState = {
  active_user: 0,
  users,
  groups,
  contacts, 
  currentChat:null,
  recentChats:[], 
  isCurrentChatLoading:false,
  sendMessageLoading:false,
  isResendLoading:false,
  isError: false,
  error: null
}

/*
currentChat: {
  customers_id: "String",
  chatHeader:"String",
  customer: {}
  messages: []
}

setCurrentChat.action.payload: {
  customers_id:"string", 
  chatHeader:"customer first and last name"
}

setCurrentChatSuccess.action.payload: {
  chatHeader:"customer first and last name", 
  messages:[]
}

setCustomerOnCurrentChatSaga.action.payload:{
  customer:{}
}

*/

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setFullUser(state, action) {
      state.users = action.payload
    },
    //executed in converationListItem.js and chatSaga.js
    getCurrentChat(state){
      state.isCurrentChatLoading = true
    },
    // executed in chatSaga.js
    setCurrentChatSaga(state, action){
      state.isCurrentChatLoading = false
      state.currentChat = action.payload
      state.recentChats = [...state.recentChats, action.payload]
    }, 
    //executed in chatSaga.js
    setCustomerOnCurrentChatSaga(state, action){
      state.currentChat.customer = action.payload
      state.recentChats = state.recentChats.map(chat=>{
        if(chat.customers_id === action.payload.id){
          chat.customer = action.payload
          return chat
        }
        return chat
      })
    },
    //executed in conversationListItem.js
    setRecentChat(state, action){
      state.currentChat = action.payload
    }, 
    //executed in 
    addChatMessage(state, action){
      const message = action.payload.message
      // updating recent chat as well as adding new message to current chat. 
      state.recentChats = state.recentChats.map(chat=>{
        if(chat?.customers_id === message.customers_id){
          chat.messages.unshift(message)
          state.currentChat = state.currentChat.customers_id === chat.customers_id ? chat : state.currentChat
          return chat
        }
        return chat
      })
    }, 
    initiateSendMessage(state){
        state.sendMessageLoading = true
        state.isError = false
        state.error = ''
    },
    setChatError(state, action) {
      state.isError = true
      state.sendMessageLoading = false
      state.error = action.payload.error
    },
    sendMessageSaga(state, action){
      state.sendMessageLoading = false
      //update the current chat
        if(state.currentChat.customers_id === action.payload.customers_id){
            state.currentChat.messages.unshift(action.payload)
        }
      // update the recent chat
          state.recentChats = state.recentChats.map(chat=>{
            if(chat?.customers_id === action.payload.customers_id){
              chat.messages.unshift(action.payload)
              return chat
            }
            return chat
          })  
    },
    setMessageUpdate(state, action){
      const {customers_id, messages} = action.payload
        if(state.currentChat?.customers_id === customers_id){
          state.currentChat.messages = messages
        }

      state.recentChats = state.recentChats.map(chat=>{
        if(chat.customers_id === customers_id){
          chat.messages = messages
          return chat
        }else{
          return chat
        }
      })
    }, 
    resendSms(state){
        state.isResendLoading = true
    },
    resendSmsSaga(state){
      state.isResendLoading = false
      //setMessageUpdate reducer is used for resend message update as well.
    }

  }, //reducer end
})


export const {getCurrentChat,
              setCurrentChatSaga,
              setCustomerOnCurrentChatSaga,
              setRecentChat,
              addChatMessage, 
              setFullUser, 
              initiateSendMessage, 
              sendMessageSaga, 
              setMessageUpdate,
              setChatError, 
              resendSms, 
              resendSmsSaga
            } = chatSlice.actions

export default chatSlice.reducer


import React from 'react'
import Routes from './routes'

//Import Scss
import './assets/scss/themes.scss'
import fakeBackend from './helpers/fake-backend'

fakeBackend()

function App() {
  return <Routes />
}

export default App

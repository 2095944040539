import { all } from 'redux-saga/effects'
import authSaga from './auth/authSaga'
import LayoutSaga from './layout/layoutSaga'
import ConversationsSaga from './conversations/conversationsSaga'
import chatSaga from './chat/chatSaga'
import locationSaga from './locations/locationSaga'
import userSaga from './users/userSaga'
import templateSaga from './templates/templateSaga'
import customerSaga from './customer/customerSaga'
import settingsSaga from './settings/settingsSaga'
import devicesSaga from './devices/devicesSaga'
import notificationSaga from './notifications/notificationSaga'

export default function* rootSaga() {
  yield all([ 
    authSaga(), 
    LayoutSaga(), 
    ConversationsSaga(), 
    chatSaga(), 
    locationSaga(),
    templateSaga(),
    userSaga(),
    customerSaga(),
    settingsSaga(), 
    devicesSaga(), 
    notificationSaga()
  ])
}


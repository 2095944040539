import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  conversations: [],
  selectedConversation: null,
  isConversationsLoading: false,
  isMessagesLoading: false,
  singleConversation: {}, 
  error:null
}
const conversationsSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    getConversations(state) {
      state.isConversationsLoading = true
    },
    getConversationsSuccess(state, action) {
      state.isConversationsLoading = false
      state.conversations = action.payload.conversations
    },
    handleSelectConversation(state, action) {
      state.selectedConversation = action.payload.conversation
    },
    addNewCustomerToConversation(state, action) {
      state.conversations = [...state.conversations, action.payload.conversation]
    },
    updateConversationStatusSuccess(state, action) {
      state.conversations = state.conversations.map((conversation) =>
        conversation.id === action.payload.conversation.id
          ? {
              ...conversation,
              isClicked: true,
            }
          : conversation,
      )
    },
    updateConversationList(state, action){
      const conversation = action.payload.conversation
        state.conversations  = state.conversations.filter(con => con.id !== conversation.id)
        state.conversations = [conversation, ...state.conversations]
     if(state.conversations.length > 100){
      state.conversations.pop(); 
     }
    }, 
    getSingleConversation(state, action){
     state.isMessagesLoading = false
    }, 
    setSingleConversationSaga(state, action){
      state.singleConversation = action.payload
    }
  },
})

export const {
  getConversations,
  getConversationsSuccess,
  handleSelectConversation,
  updateConversationStatusSuccess,
  updateConversationList, 
  addNewCustomerToConversation,
  getSingleConversation, 
  setSingleConversationSaga
} = conversationsSlice.actions

export default conversationsSlice.reducer

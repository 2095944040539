import axios from 'axios'

// default
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT+"/api"

//with Credentials
axios.defaults.withCredentials = true
// content type
axios.defaults.headers.post['Content-Type'] = 'application/json'

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger

    console.log("From axios: ",isNaN(error.response.status))
    let message
    switch (parseInt(error.response.status)) {
      case 500:
        message = 'Internal Server Error'
        break
      case 401:
        message = 'Invalid credentials'
        break
      case 404:
        message = 'Sorry! the data you are looking for could not be found'
        break
      case 403:
        message ='Incorrect email or password'
        break
      default:
        message = error.message || error
    }
    return Promise.reject(message)
  },
)

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

class APIClient {
  /**
   * Fetches data from given url
   */
  get = (url, params) => {
    return axios.get(url, params)
  }

  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data)
  }

  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data)
  }
  /**
   * 
   * Delete record from the url
   */
  delete = (url) => {
    return axios.delete(url)
  }

  /**
   * PUT
   */
  put = (url, data) => {
    return axios.put(url, data)
  }
}

export { APIClient, setAuthorization }

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  users: [],
  isButtonLoading: false, 
  error: null
}
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {

    addUser(state) {
      state.isButtonLoading = true
    },
    editUser(state) {
      state.isButtonLoading = true
    },
    removeUser(state) {
      state.isButtonLoading = true
    },

    setUsersSaga(state, action) {
      state.users = action.payload.users
    },
    // executed in userSaga.js
    addUserSaga(state, action) {
      state.users = [...state.users, action.payload.user]
      state.isButtonLoading = false
    },

    // executed in userSaga.js
    editUserSaga(state, action) {
      state.users = state.users.map((user) =>
        user.id === action.payload.user.id ? action.payload.user : user,
      )
      state.isButtonLoading = false
    },

    // executed in userSaga.js
    removeUserSaga(state, action) {
      state.users = state.users.filter(
        (user) => user.id !== action.payload.userId,
      )
      state.isButtonLoading = false
    }
  },
})

export const { setUsers, editUser, editUserSaga, addUser, addUserSaga, removeUser, removeUserSaga, setUsersSaga } = userSlice.actions

export default userSlice.reducer

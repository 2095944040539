/**
 * Sets the logged in user
 */
const setLoggedInUser = (user) => {
  sessionStorage.setItem('authUser', JSON.stringify(user))
}

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
  const user = sessionStorage.getItem('authUser')
  return user ? (typeof user == 'object' ? user : JSON.parse(JSON.parse(user))) : null
}

/*
*Delete logged in user 
*/

const deleteLoggedInUser =()=>{
  sessionStorage.removeItem('authUser')
}

export { setLoggedInUser, getLoggedInUser, deleteLoggedInUser }

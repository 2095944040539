import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import {
  Nav,
  NavLink,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap'
import { connect, useDispatch, useSelector } from 'react-redux'
import { setActiveTab, changeLayoutMode } from '../../redux/layout/layoutSlice'
import { setActiveSetting } from '../../redux/settings/settingsSlice'
import {logoutUser} from '../../redux/auth/authSlice'
import { NavSingleItem } from '../../components/GlobalComponents/Navs/NavSingleItem'
//Import Images
import logo from '../../assets/images/logo.svg'
import avatar1 from '../../assets/images/users/avatar-1.jpg'

function MainSideMenu(props) {
  const dispatch = useDispatch()
  const navigateTo = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname === '/settings' && (props.user.isAdmin === 1 || props.user.isSupperAdmin === 1)) {
      props.setActiveTab({ tab: 'settings' })
    }
  }, [location.pathname, props])

  const { layoutMode } = useSelector((state) => ({
    layoutMode: state.Layout.layoutMode,
  }))

  const mode = layoutMode === 'dark' ? 'light' : 'dark'

  const onChangeLayoutMode = (value) => {
    if (changeLayoutMode) {
      dispatch(changeLayoutMode(value))
    }
  }

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [dropdownOpenMobile, setDropdownOpenMobile] = useState(false)

  const toggle = () => setDropdownOpen(!dropdownOpen)
  const toggleMobile = () => setDropdownOpenMobile(!dropdownOpenMobile)

  const navigateTab = (tab) => {
    navigateTo(`/${tab.parentTab}`)
    props.setActiveTab({ tab: tab.tabName })
  }

  function handleLogout(){
    dispatch(logoutUser({}))
}
  return (
    <React.Fragment>
      <div className='side-menu flex-lg-column me-lg-1'>
        {/* LOGO */}
        <div className='navbar-brand-box'>
          <Link to='/chat' className='logo logo-dark'>
            <span className='logo-sm'>
              <img src={logo} alt='logo' height='30' />
            </span>
          </Link>

          <Link to='/chat' className='logo logo-light'>
            <span className='logo-sm'>
              <img src={logo} alt='logo' height='30' />
            </span>
          </Link>
        </div>
        {/* end navbar-brand-box  */}

        {/* Start side-menu nav */}
        <div className='flex-lg-column my-auto'>
          <Nav
            pills
            className='side-menu-nav justify-content-center'
            role='tablist'
          >
             <NavSingleItem
              navTitle='Notification'
              tabName='notification'
              iconName='ri-notification-4-line'
              data= {props.notifications}
              activeTab={props.activeTab}
              changeTab={() =>
                navigateTab({ tabName: 'notification', parentTab: 'chat' })
              }
            />
            <NavSingleItem
              navTitle='Chats'
              tabName='chat'
              iconName='ri-message-3-line'
              data={props.conversations}
              activeTab={props.activeTab}
              changeTab={() =>
                navigateTab({ tabName: 'chat', parentTab: 'chat' })
              }
            />

            <NavSingleItem
              navTitle='Customers'
              tabName='customer'
              iconName='ri-contacts-line'
              activeTab={props.activeTab}
              changeTab={() =>
                navigateTab({ tabName: 'customer', parentTab: 'chat' })
              }
            />

            {
              props?.user?.isAdmin === 1 || props?.user?.isSupperAdmin === 1 ? <NavSingleItem
                navTitle='Settings'
                tabName='settings'
                iconName='ri-settings-2-line'
                activeTab={props.activeTab}
                changeTab={() =>
                  navigateTab({ tabName: 'settings', parentTab: 'settings' })
                }
              /> : null
            }


            <Dropdown
              nav
              isOpen={dropdownOpenMobile}
              toggle={toggleMobile}
              className='profile-user-dropdown d-inline-block d-lg-none dropup'
            >
              <DropdownToggle nav>
                <img
                  src={avatar1}
                  alt='chatvia'
                  className='profile-user rounded-circle'
                />
              </DropdownToggle>
              <DropdownMenu className='dropdown-menu-end'>
                <DropdownItem onClick={handleLogout}>
                  Log out {' '}
                  <i className='ri-logout-circle-r-line float-end text-muted'></i>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </div>
        {/* end side-menu nav */}

        <div className='flex-lg-column d-none d-lg-block'>
          <Nav className='side-menu-nav justify-content-center'>
            <li className='nav-item'>
              <NavLink id='light-dark' onClick={() => onChangeLayoutMode(mode)}>
                <i className='ri-sun-line theme-mode-icon'></i>
              </NavLink>
            </li>
            <Dropdown
              nav
              isOpen={dropdownOpen}
              className='nav-item btn-group dropup profile-user-dropdown'
              toggle={toggle}
            >
              <DropdownToggle className='nav-link centerify' tag='a'>
                <div className='avatar-xs'>
                  <span className='avatar-title rounded-circle bg-soft-primary text-primary'>
                    A
                  </span>
                </div>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={handleLogout}>
                  Log out {' '}
                  <i className='ri-logout-circle-r-line float-end text-muted'></i>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </div>
        {/* Side menu user */}
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
    ...state.Setting,
    ...state.Auth, 
    notifications:state.Notifications.notifications, 
    conversations: state.Conversations.conversations
  }
}

MainSideMenu = connect(mapStatetoProps, {
  setActiveTab,
  setActiveSetting,
})(MainSideMenu)

export { MainSideMenu }

import {all, put, takeEvery, call} from 'redux-saga/effects'
import { APIClient } from '../../helpers/apiClient'
import { getCurrentChat, 
        setCustomerOnCurrentChatSaga,
        setCurrentChatSaga, 
        initiateSendMessage, 
        sendMessageSaga, 
        setChatError, 
        resendSms,
        resendSmsSaga, 
        setMessageUpdate } from './chatSlice'

const api = new APIClient();

function* processGetCurrentChat({payload}){
    try{
        let customer = payload?.customer ? payload.customer : null
        
        if(!customer){
            customer = yield call(api.get, `/customers/${payload.customers_id}`)
        }
      
        const messages = yield call(api.get,`/sms/${payload.customers_id}?limit=50`)


        const chatHeader = payload?.chatHeader ?? `${customer.firstName} ${customer.lastName}`
        
        yield put(setCurrentChatSaga({
            customers_id: payload.customers_id,
            chatHeader,
            messages: messages
        }))

        yield put(setCustomerOnCurrentChatSaga(customer))

    }catch(error){
        console.log("Error while processing getChatMessages", error)
    }
}


// resendSms 

function* processResendSms({payload}){
    try{
      const result =   yield api.put("/sms/resendsms", payload)

      console.log(result)
      yield put(resendSmsSaga())
      yield put(setMessageUpdate(result))

    }catch(error){
        console.log("Error while resendingSms: ", error)
    }
}

// Send sms 
function* processSendMessage({ payload }){
    try{
       const message =  yield api.create('/sms/send', payload)

        yield put(sendMessageSaga(message))
        
    }catch(error){
        yield put(setChatError({ error: 'Ops! message failed to send. Try again.' }))
    }
}

export function* watchSetCurrentChat(){

    yield takeEvery(getCurrentChat().type, processGetCurrentChat)
    yield takeEvery(initiateSendMessage().type, processSendMessage)
    yield takeEvery(resendSms().type, processResendSms)
}

function* chatSaga(){
    yield all([ watchSetCurrentChat() ])
}

export default chatSaga
import React, { Fragment, useEffect, useState } from 'react'
import classnames from 'classnames'
import { NavItem, NavLink } from 'reactstrap'
//import { useSelector } from 'react-redux'

function NavSingleItem(props) {
  const [badge, setBadge] = useState(0)

 useEffect(()=>{
  if(props?.data){
    const unClickedConversations = props.data.filter(con=>!con.isClicked); 
    setBadge(unClickedConversations.length); 
  }
   
  })
 
  return (
    <Fragment>
      <NavItem
        id={`${props.tabName}`}
        style={{ position: 'relative' }}
        onClick={() => props.changeTab()}
      >
        { badge > 0 ? (
          <div className='chat-unread-message' id={'unRead' + badge}>
            <span className='badge badge-soft-danger rounded-pill'>
              {badge}
            </span>
          </div>
        ) : null}

        <NavLink
          id='pills-chat-tab'
          className={classnames({
            active: props.activeTab === props.tabName,
          })}
        >
          <i className={props.iconName}></i>
        </NavLink>
      </NavItem>
    </Fragment>
  )
}

export { NavSingleItem }

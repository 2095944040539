import React, { Component, cloneElement } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { changeLayoutMode } from '../../redux/layout/layoutSlice'
import { LogoutRedirect } from './logoutRedirect'
import { MainSideMenu } from '../GlobalLayout/mainSideMenu'
import withRouter from '../../routes/util'
import { SocketListener } from '../../components/EventComponents/socketListener'

class AuthLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.capitalizeFirstLetter.bind(this)
  }
  
  //function for capital first letter of current page pathname
  capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2)
  }

  componentDidMount() {
    var getLayoutMode = sessionStorage.getItem('layoutMode')
    this.props.changeLayoutMode(getLayoutMode)
    if (getLayoutMode) {
      this.props.changeLayoutMode(getLayoutMode)
    } else {
      this.props.changeLayoutMode(this.props.layout.layoutMode)
    }

    let currentage = this.capitalizeFirstLetter(
      this.props.router.location.pathname,
    )

    //set document title according to page path name
    document.title =
      currentage + ' | PRIVYSMS'
  }

  render() {
    return (
      <React.Fragment>
        <div className='layout-wrapper d-lg-flex'>
          {/*redirect if there is no logged in user*/}
          <LogoutRedirect/>
          {/* left sidebar menu */}
          {/* <LeftSidebarMenu /> */}
          <MainSideMenu />
          {/*render socket listeners*/}
          <SocketListener/>
          {/* render page content */}
          {cloneElement(this.props.children)}
        </div>
      </React.Fragment>
    )
  }
}

AuthLayout.propTypes = {
  layoutMode: PropTypes.any,
}

const mapStateToProps = (state) => {
  const { layoutMode } = state.Layout
  return { layoutMode }
}

AuthLayout = withRouter(
  connect(mapStateToProps, { changeLayoutMode })(AuthLayout),
)

export { AuthLayout }

import { all, put, takeEvery } from 'redux-saga/effects'
import { APIClient } from '../../helpers/apiClient'

import { getNotifications, getNotificationsSaga, handleIsClicked, handlIsClickedSaga } from './notificationSlice'



const api = new APIClient()

function* processGetNotifications(){
    try{
        const notifications = yield api.get('/notifications')

        yield put(getNotificationsSaga(notifications))

    }catch(error){
        console.log("Error getting Notifications: ", error)

    }
}

function* processHandleIsClicked({payload}){
    try{
        yield api.put(`/notifications/togglenotificationisclicked/${payload.id}?isclicked=1`)
        yield put(handlIsClickedSaga(payload))

    }catch(error){
        console.log("Notification click handler: ", error)

    }
}


export function* watchNotificationProcess(){
    yield takeEvery( getNotifications().type, processGetNotifications)
    yield takeEvery(handleIsClicked().type, processHandleIsClicked)
}

function* NotificationSaga(){
    yield all([watchNotificationProcess()])
}
export default NotificationSaga
import { combineReducers } from 'redux'

import Auth from './auth/authSlice'
import Devices from './devices/devicesSlice'
import Chat from './chat/chatSlice'
import Layout from './layout/layoutSlice'
import Location from './locations/locationSlice'
import Setting from './settings/settingsSlice'
import User from './users/userSlice'
import Template from './templates/templateSlice'
import Conversations from './conversations/conversationsSlice'
import Customer from './customer/customerSlice'
import Notifications from './notifications/notificationSlice'

export default combineReducers({
  Auth,
  Devices,
  Chat,
  Location,
  Layout,
  User,
  Template,
  Setting,
  Conversations,
  Customer,
  Notifications
})

import { all, takeEvery, put } from 'redux-saga/effects'
import { APIClient  } from '../../helpers/apiClient'
import {
    addTemplate,
    editTemplate,
    removeTemplate,
    addTemplateSaga,
    editTemplateSaga,
    removeTemplateSaga,
    getTemplateHandles,
    getTemplateHandlesSaga
} from './templateSlice'

const api = new APIClient()

// worker sagas
function* processTemplateAdd({ payload }){
    try {
        const template = yield api.create('templates', payload.template)

        yield put(addTemplateSaga({ template }))
    } catch (er) {
    }
}

function* processGetTemplatesHandles(){
    try {
        const handles = yield api.get(`templates/handles`)

        yield put(getTemplateHandlesSaga({ handles }))
    } catch (er) {

    }
}

function* processTemplateEdit({ payload }){
    try {
        const template = yield api.put(`templates/${payload.template.id}`, payload.template)

        yield put(editTemplateSaga({ template }))
    } catch (er) {
    }
}

function* processTemplateRemove({ payload }){
    try {
        yield api.delete(`templates/${payload.templateId}`);

        yield put(removeTemplateSaga(payload))
    } catch (er) {
    }
}

// watcher sagas
export function* watchProcesses(){
    yield takeEvery(addTemplate().type, processTemplateAdd)
    yield takeEvery(editTemplate().type, processTemplateEdit)
    yield takeEvery(removeTemplate().type, processTemplateRemove)
    yield takeEvery(getTemplateHandles().type, processGetTemplatesHandles)
}

function* templateSaga() {
    yield all([ watchProcesses() ])
}

export default templateSaga
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { APIClient } from '../../helpers/apiClient'
import { apiError, loginUserSuccess, loginUser, logoutUser } from './authSlice'
import {setLoggedInUser, deleteLoggedInUser} from '../../helpers/authUtils'
import {socket} from '../../sockets/io'

/**
 * Sets the session
 * @param {*} user
 */

const api = new APIClient()
//const create = api.create

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload }) {
  const { email, password, navigate } = payload
  try {

    const response = yield call(api.create, '/users/login', { email, password })
    
    setLoggedInUser(JSON.stringify(response))
    //sessionStorage.setItem('authUser', JSON.stringify(response))
    yield put(loginUserSuccess(response))
    navigate('/chat')
  } catch (error) {
    //console.log("Login Saga Error: ", error)
    yield put(apiError(error))
  }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({payload}) {
  try {
    yield deleteLoggedInUser()
    yield socket.disconnect()
    if(payload?.soft){
      yield call(api.get,'/users/softlogout')
  }else{
    yield call(api.get,'/users/logout')
  }
  } catch (error) {
    console.log(error)
  }
}

export function* watchAll() {
  yield takeEvery(loginUser().type, login)
  yield takeEvery(logoutUser().type, logout)
}

function* authSaga() {
  yield all([watchAll()])
}

export default authSaga

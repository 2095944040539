import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { changeLayoutMode } from '../redux/layout/layoutSlice'
import withRouter from '../routes/util'

class NonAuth extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.capitalizeFirstLetter.bind(this)
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2)
  }

  componentDidMount() {
    var getLayoutMode = sessionStorage.getItem('layoutMode')
    this.props.changeLayoutMode(getLayoutMode)
    if (getLayoutMode) {
      this.props.changeLayoutMode(getLayoutMode)
    } else {
      this.props.changeLayoutMode(this.props.layoutMode)
    }

    let currentage = this.capitalizeFirstLetter(
      this.props.router.location.pathname,
    )

    document.title =
      currentage + ' | PRIVYSMS'
  }
  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>
  }
}

NonAuth.propTypes = {
  layoutMode: PropTypes.any,
}

const mapStateToProps = (state) => {
  const { layoutMode } = state.Layout
  return { layoutMode }
}

NonAuth = withRouter(connect(mapStateToProps, { changeLayoutMode })(NonAuth))

export { NonAuth }

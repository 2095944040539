import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    customers: [],
    filteredCustomers: [],
    customerDetails: [],
    isButtonLoading: false,
    isListLoading: true, 
    appointmentIsLoading: false, 
    error: null
}

const customerSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {
        addCustomer(state) {
            state.isButtonLoading = true
        },
        updateCustomer(state) {
            state.isButtonLoading = true
        },
        banCustomer(state) {
            state.isButtonLoading = true
        },
        searchCustomer(state) {
            state.isListLoading = true
        },
        setCustomer(state) {
            state.isListLoading = true
        },
        setCustomerSaga(state, action) {
            state.customers = action.payload.customers

            state.isListLoading = false
        },
        addCustomerSaga(state, action) {
            state.customers = [...state.customers, action.payload.customer]
            state.isButtonLoading = false
        },
        searchCustomerSaga(state, action) {
            state.filteredCustomers = action.payload.filteredCustomers
            state.isListLoading = false
        },

        updateCustomerSaga(state, action) {
            state.customers = state.customers.map( customer=>{
                if(customer.id === action.payload.customer.id){
                    const updatedCustomer =  {
                        ...action.payload.customer, 
                        appointments: state.customerDetails?.appointments
                    }
                    state.customerDetails = updatedCustomer
                    return updatedCustomer
                    }else {
                        return customer 
                }
            })
            state.isButtonLoading = false
        }, 

        getAppointments(state){
            state.appointmentIsLoading = true
        }, 
        setAppointmentsSaga(state, action){
            state.appointmentIsLoading = false
            state.customerDetails.appointments = action?.payload ?? []

        }, 
        setCustomerDetails(state, action){
            state.customerDetails = action.payload
        }
    }
})

export const {  addCustomer, 
                updateCustomer, 
                addCustomerSaga, 
                updateCustomerSaga, 
                searchCustomerSaga, 
                searchCustomer, 
                setCustomer, 
                setCustomerSaga, 
                getAppointments,
                setAppointmentsSaga, 
                setCustomerDetails,
                banCustomer
            } = customerSlice.actions

export default customerSlice.reducer
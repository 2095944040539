import { all, put, takeEvery } from 'redux-saga/effects'
import { APIClient } from '../../helpers/apiClient'
import { apiError } from '../auth/authSlice'
import {
  getConversationsSuccess,
  updateConversationStatusSuccess,
  getConversations as getConversationStore,
  handleSelectConversation, 
  getSingleConversation, 
  setSingleConversationSaga 

} from './conversationsSlice'

const api = new APIClient()

function* getConversations() {
  try {
    const conversations = yield api.get('/conversations')

    yield put(getConversationsSuccess({ conversations}))

  } catch (error) {
    yield put(apiError(error))
  }
}

function* updateConversationStatus({ payload }) {
  try {
    if(!payload.conversation.isClicked){
      yield api.put(`conversations/toggleconversationisclicked/${payload.conversation.id}?isclicked=1`)
    }
    
    yield put(updateConversationStatusSuccess({
        conversation: payload.conversation
      })
    )
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getConversationByID({payload}){
    try{
        const conversation = yield api.get(`/conversations/${payload}`)
        yield put(setSingleConversationSaga(conversation))
    } catch(error){
      console.log("Error while getting conversationByID: ", error)

    }
}


export function* watchConversationProcess(){
  yield takeEvery(getConversationStore().type, getConversations)
  yield takeEvery(handleSelectConversation().type, updateConversationStatus)
  yield takeEvery(getSingleConversation().type, getConversationByID)
}

function* ConversationSaga() {
  yield all([watchConversationProcess()])
}

export default ConversationSaga



/**
 * Sets devices
 */
 const setDevices = (devices) => {
    sessionStorage.setItem('devices', JSON.stringify(devices))
  }
  
  /**
   * Returns devices
   */
  const getDevices = () => {
    const devices = sessionStorage.getItem('devices')
    return devices ? (Array.isArray(devices) ? devices : JSON.parse(devices)) : null
  }
  
  export { setDevices, getDevices }
  
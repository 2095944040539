
import React, {useEffect, useRef, useState} from 'react'
//import {Howl} from 'howler'
import { useDispatch, useSelector } from 'react-redux'
import {connectSocket} from '../../sockets/io'
import {socketEvent, common} from '../../sockets/io'
import {updateConversationList, } from '../../redux/conversations/conversationsSlice'
import{addChatMessage, setMessageUpdate} from '../../redux/chat/chatSlice'
import{logoutUser} from '../../redux/auth/authSlice'
import {handleNewNotification} from '../../redux/notifications/notificationSlice'

function SocketListener(){

    const [rendered, setRendered] = useState(false); 
    const dispatch = useDispatch()
    const user = useSelector(state=>state.Auth.user)

   // const recentChats = useSelector(state=>state.Chat.recentChats)
    useEffect(()=>{
        if(!rendered){
        //listen for errors
        connectSocket(); 

        }
    },[])
  

    useEffect(()=>{
        //listing for incoming sms sent from customer
        socketEvent.on(common.INCOMING_SMS, payload=>{
            
            dispatch(updateConversationList({conversation: payload.data.conversation})); 
            dispatch(addChatMessage({message: payload.data.savedSms}))
        
        })
     }, [])


    useEffect(()=>{
        // listing for the updated outgoing sms
        //This event is triggered when sms from client status is updated
        if(!rendered){
            socketEvent.on(common.SMS_STATUS_UPDATE, payload=>{
            const {chat} = payload.data
            dispatch(setMessageUpdate(chat))
            
        })
    }
    }, [])

    useEffect(()=>{
        //listen for logout user event
        socketEvent.on(common.LOGOUT_USER, payload=>{
            const {id} = payload.data
           if(id===user.id){
            dispatch(logoutUser({soft:true}))
           }
        })
    }, [])

    const audioRef = useRef(null); 
    const audioFilePath = 'https://privysms-audio.s3.eu-west-2.amazonaws.com/mixkit.mp3'

    async function playSound(){
        //const notificationSound = new Audio(audioFilePath)
        await audioRef.current.play()
    }

    useEffect(()=>{
        if(!rendered){
        setRendered(true); 
        //listen for notificaiton event
        
            socketEvent.on(common.NOTIFICATION, payload=>{
            dispatch(handleNewNotification(payload.data))
            playSound()
        })
    }
    }, [])

    return(
        <React.Fragment>
            <audio src={audioFilePath} ref={audioRef}></audio>
        </React.Fragment>

    )

}

export {SocketListener}
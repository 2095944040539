import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  templates: [],
  handles: [],
  isButtonLoading: false,
  error: null
}
const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    addTemplate(state) {
      state.isButtonLoading = true
    },

    editTemplate(state) {
      state.isButtonLoading = true
    },

    removeTemplate(state) {
      state.isButtonLoading = true
    },

    getTemplateHandles(state){
      state.isButtonLoading = true
    },

    getTemplateHandlesSaga(state, action){
      const handles = Object.values(action.payload.handles)

      state.isButtonLoading = false
      state.handles = handles
    },

    setTemplatesSaga(state, action) {
      state.templates = action.payload.templates
    },

    addTemplateSaga(state, action) {
      state.templates = [...state.templates, action.payload.template]
      state.isButtonLoading = false
    },

    editTemplateSaga(state, action) {
      state.templates = state.templates.map((template) =>
      template.id === action.payload.template.id
        ? action.payload.template
        : template,
    )
    state.isButtonLoading = false
    },
    removeTemplateSaga(state, action) {
      state.templates = state.templates.filter(
        (template) => template.id !== action.payload.templateId,
      )
      state.isButtonLoading = false
    }

  },
})

export const { setTemplatesSaga, addTemplate, editTemplate, removeTemplate, addTemplateSaga, editTemplateSaga, removeTemplateSaga, getTemplateHandles, getTemplateHandlesSaga } =
  templateSlice.actions
export default templateSlice.reducer

import React from 'react'
import { Navigate } from 'react-router-dom'
import { AuthLayout } from '../layouts/AuthLayout/authLayout'
import { NonAuth } from '../layouts/NonAuth'

// lazy load all the views
// import { ChatPage } from '../pages/ChatPage'

const SettingsPage = React.lazy(() => import('../pages/SettingsPage'))
const ChatPage = React.lazy(() => import('../pages/ChatPage'))

// auth

// const Login = React.lazy(() => import("../pages/Auth/Login"));
const LoginPage = React.lazy(() => import('../pages/LoginPage'))
const Logout = React.lazy(() => import('../pages/Auth/Logout'))
// const ForgetPassword = React.lazy(() => import("../pages/Auth/ForgetPassword"));
// const Register = React.lazy(() => import("../pages/Auth/Register"));
// const LockScreen = React.lazy(() => import("../pages/Auth/LockScreen"));

// declare all routes
const adminProtectedRoutes = [
  {
    path: '/settings',
    component: ( <AuthLayout>
      <SettingsPage />
    </AuthLayout>)
  },
  {
    path: '*',
    component: (
      <Navigate to="/chat" />)
  }
]
const authProtectedRoutes = [
  {
    path: '/chat',
    component: (
      <AuthLayout>
        <ChatPage />
      </AuthLayout>
    ),
  },
  {
    path: '/logout',
    component: (
      <AuthLayout>
        <Logout />
      </AuthLayout>
    ),
  },
  {
    // logged in users should not be allowed to path: /
    path: '/',
    component: (
      <AuthLayout>
        <Navigate to='/chat' />
      </AuthLayout>
    )
  },{
    path: '*',
    component: (<AuthLayout>
      <Navigate to="/chat" />
    </AuthLayout>)
  }
]

const publicRoutes = [
  {
    path: '/',
    component: (
      <NonAuth>
        <LoginPage />
      </NonAuth>
    ),
  },
  {
    path: '/chat',
    exact: true,
    component: (
      <NonAuth>
        <Navigate to='/' />
      </NonAuth>
    ),
  },
  {
    path: '/settings',
    exact: true,
    component: (
      <NonAuth>
        <Navigate to='/' />
      </NonAuth>
    ),
  },
  {
    path: '/logout',
    exact: true,
    component: (
      <NonAuth>
        <Navigate to='/' />
      </NonAuth>
    ),
  },
  {
    path: "*",
    component: (
      <Navigate to="/" />
    )
  }
]

const routes = [...authProtectedRoutes, ...publicRoutes, ...adminProtectedRoutes]

export { authProtectedRoutes, publicRoutes, adminProtectedRoutes, routes }

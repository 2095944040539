import {io} from 'socket.io-client'
import constant from './constant'
import EventEmitter from 'eventemitter3'

let reconnecting = false; 

 export const socket = io(process.env.REACT_APP_API_ENDPOINT, {
    transports: ["polling"], 
    withCredentials: true, 
    autoConnect: false

 }); 

 export function connectSocket(){
      if(!socket.connected){
         socket.connect()
      }
   }

 export function reconnectSocket(){
   let count = 0
   if(!reconnecting){  
   const intervalID = setInterval(()=>{
      reconnecting = true
         if(!socket.connected){
            connectSocket()
            count++
            console.log("count: ", count)
         }else if(count > 10){
               clearInterval(intervalID)
               reconnecting = false
         }else{
            clearInterval(intervalID)
            reconnecting = false
         }
         
      }, 10000)
   }
 }  

 socket.on("disconnect", (reason) => {
   if (reason.trim() === 'io server disconnect'.trim() || reason.trim() === 'io client disconnect'.trim()) {
      return
      }else{
         reconnectSocket(); 
      }
   });

socket.on("connect_error", () => {
         reconnectSocket(); 
   });

 socket.on('connect',()=>{
   //console.log("Socket Connected")
 })

export const socketEvent = new EventEmitter()

  socket.on(constant.FROM_SERVER, (data)=>{

  socketEvent.emit(data?.subscriber, data); 

   }); 

export const common  = constant


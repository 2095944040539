import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activeTab: 'chat',
  userSidebar: false,
  conversationName: 'Doris Brown',
  layoutMode: 'dark',
  hasCustomPayload: false,
  customPayload: null,
  error: null
}

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setActiveTab(state, action) {
      state.activeTab = action.payload.tab
      state.hasCustomPayload = action.payload.hasCustomPayload
      state.customPayload = action.payload.customPayload
    },
    openUserSidebar(state) {
      state.userSidebar = true
    },
    closeUserSidebar(state) {
      state.userSidebar = false
    },
    changeLayoutMode(state, action) {
      state.layoutMode = action.payload
    },
  },
})

export const {
  setActiveTab,
  openUserSidebar,
  closeUserSidebar,
  changeLayoutMode,
} = layoutSlice.actions

export default layoutSlice.reducer

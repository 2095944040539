import React, {useEffect} from 'react';
import {connect } from 'react-redux'
import {redirect } from "react-router-dom";


function LogoutRedirect({user}){
    useEffect(()=>{
        if(!user){
            redirect('/')
        }
    },[user])


    return( <React.Fragment/>)
}

const mapStateToProps = (state)=>{
    const {user} = state.Auth.user

    return {user}
}

LogoutRedirect = connect(mapStateToProps)(LogoutRedirect)

export {LogoutRedirect}
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    devices: [], 
    error: null
}

const devicesSlice = createSlice({
    name: 'devices', 
    initialState, 
    reducers: {
        initiateGetDevices(state){
            return state
        }, 
        setDevicesSaga(state, action){
            state.devices = action.payload
        }
    }
})

export const { initiateGetDevices,setDevicesSaga } = devicesSlice.actions

export default devicesSlice.reducer
import { all, put, takeEvery } from 'redux-saga/effects'
import { APIClient } from '../../helpers/apiClient'

import { getConversationsSuccess } from '../conversations/conversationsSlice'
import {
    addCustomer,
    updateCustomer,
    addCustomerSaga,
    updateCustomerSaga,
    searchCustomer,
    searchCustomerSaga,
    setCustomer,
    setCustomerSaga,
    banCustomer,
    getAppointments, 
    setAppointmentsSaga
} from './customerSlice'

const api = new APIClient()

function* processsAddCustomer({ payload }){
    try {
        const customer = yield api.create('customers', payload.customer)
        yield put(addCustomerSaga({ customer }))

        const response = yield api.get('conversations')
        yield put(getConversationsSuccess({ conversations: response }))

    } catch (er) {
    }
}
function* processSetCustomer({ payload }) {
    try {
        const customers = yield api.get(`customers?limit=${payload.limit}`)
        
        yield put(setCustomerSaga({ customers }))
    } catch (er) {
    }
}

function* processUpdateCustomer({ payload }){
    try {
        const customer = yield api.put(`customers/${payload.customer.id}`, payload.customer)

        yield put(updateCustomerSaga({ customer }))
    } catch (er) {
    }
}

function* processSearchCustomer({ payload }) {
    try {
        const filteredCustomers = yield api.get(`customers/search?term=${payload.term}`)

        yield put(searchCustomerSaga({ filteredCustomers }))
    } catch (er) {
    }
}

function* processBanCustomer({ payload }) {
    try {
      const customer = yield api.put(`customers/isban/${payload.customerId}?isban=${payload.banValue}`)

      yield put(updateCustomerSaga({ customer }))
    } catch (er) {
    }
}
function* getRecentAppointments({payload}){
    try{
        const appointments = yield api.get(`appointments/recent/${payload}`)
        
        yield put(setAppointmentsSaga(appointments))

    }catch(error){
        console.log("Error from customerSaga: ", error)
    }
}

export function* watchProcesses() {
    yield takeEvery(addCustomer().type, processsAddCustomer)
    yield takeEvery(updateCustomer().type, processUpdateCustomer)
    yield takeEvery(searchCustomer().type, processSearchCustomer)
    yield takeEvery(setCustomer().type, processSetCustomer)
    yield takeEvery(banCustomer().type, processBanCustomer)
    yield takeEvery(getAppointments().type, getRecentAppointments)
}

function* customerSaga(){
    yield all([ watchProcesses() ])
}

export default customerSaga
import { all, takeEvery, put } from 'redux-saga/effects'
import { APIClient } from '../../helpers/apiClient'
import { setUsersSaga } from '../users/userSlice'
import { setLocationsSaga } from '../locations/locationSlice'
import { setTemplatesSaga } from '../templates/templateSlice'
import { fetchInitialData, disableLoadingSaga } from './settingsSlice'

const api = new APIClient()

function* processFetchData() {
    try {
        const locations = yield api.get('locations')
        yield put(setLocationsSaga({ locations }))

        const templates = yield api.get('templates')
        yield put(setTemplatesSaga({ templates }))

        const users = yield api.get('users/getusers')
        yield put(setUsersSaga({ users }))
        
        yield put(disableLoadingSaga())

    } catch (er) {
    }
}

export function* watchProcesses() {
 yield takeEvery(fetchInitialData().type, processFetchData)
}

function* settingsSaga() {
  yield all([ watchProcesses() ])
}

export default settingsSaga


const constant = {
    
    FROM_SERVER:"server/client", 
    FROM_CLIENT:"client/server",
    INCOMING_SMS:"incoming/sms",
    OUTGOING_SMS:"outgoing/sms", 
    UPDATED_CONVERSATION:"conversation/update",
    CONVERSATION_ISCLICKED:"conversation/isclicked", 
    SMS_STATUS_UPDATE:"sms/statusupdate", 
    LOGOUT_USER:"auth/logoutUser", 
    UPDATE_APPOINTMENT:"update/appointment", 
    NOTIFICATION:"push/notification"

}

export default constant
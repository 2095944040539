import { all, takeEvery, put } from 'redux-saga/effects'
import { APIClient  } from '../../helpers/apiClient'
import { 
    addUser,
    addUserSaga,
    editUser, 
    editUserSaga,
    removeUser,
    removeUserSaga
} from './userSlice'

const api = new APIClient()

// worker sagas
function* processUserAdd({ payload }){
    try {
       const user = yield api.create('users/signup', payload.user);

       yield put(addUserSaga({ user }))
    } catch (er) {
    }
}

function* processUserEdit({ payload }){
    try {
        const user = yield api.put(`users/updateuser/${payload.user.id}`, payload.user);

        yield put(editUserSaga({ user }))
    } catch (er) {
    }
}

function* processUserRemove({ payload }){
   console.log("inside processRemoveUser: ",payload)
   
    try {
       const result =  yield api.delete(`users/deleteuser/${payload.userId}`)
       if(result.status !== 204) throw result.data
        yield put(removeUserSaga(payload))
    } catch (er) {
        console.log('Inside userSaga.js: ', er)
    }
}

// watcher sagas
export function* watchProcesses(){
    yield takeEvery(addUser().type, processUserAdd)
    yield takeEvery(editUser().type, processUserEdit)
    yield takeEvery(removeUser().type, processUserRemove)

}

function* userSaga() {
    yield all([ watchProcesses() ])
}

export default userSaga
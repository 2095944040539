import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  locations: [],
  isButtonLoading: false,
  error:null
}
const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    // executed in locationSaga.js and locationsAdd.js
    addLocation(state) {
      state.isButtonLoading = true
    },

    // executed in locationSaga.js and locationsList.js
    removeLocation(state) {
      state.isButtonLoading = true
    },

    // executed in locationSaga.js and 
    editLocation(state) {
      state.isButtonLoading = true
    },

    setLocationsSaga(state, action) {
      state.locations = action.payload.locations
    },
    // executed in locationSaga.js
    addLocationSaga(state, action) {
      state.locations = [...state.locations, action.payload.location]
      state.isButtonLoading = false
    },

    // executed in locationSaga.js
    editLocationSaga(state, action) {
      state.locations = state.locations.map((location) =>
        location.id === action.payload.location.id
          ? action.payload.location
          : location,
      )
      state.isButtonLoading = false
    },

    // executed in locationSaga.js
    removeLocationSaga(state, action) {
      state.locations = state.locations.filter(
        (location) => location.id !== action.payload.locationId,
      )
      state.isButtonLoading = false
    },


  },
})

export const { setLocationsSaga, addLocation, addLocationSaga, editLocation, editLocationSaga, removeLocation, removeLocationSaga } =
  locationSlice.actions

export default locationSlice.reducer

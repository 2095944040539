// @flow
import { all, call, fork, takeEvery } from 'redux-saga/effects'
import { changeLayoutMode } from './layoutSlice'

/**
 * Changes the body attribute
 */
function changeBodyAttribute(attribute, value) {
  if (document.body) document.body.setAttribute(attribute, value)
  return true
}

/**
 * Changes the layout mode
 * @param {*} param0
 */
function* changelayoutMode({ payload: layoutMode }) {
  try {
    if (layoutMode === 'light') {
      yield call(changeBodyAttribute, 'data-layout-mode', layoutMode)
      sessionStorage.setItem('layoutMode', layoutMode)
    } else if (layoutMode === 'dark') {
      yield call(changeBodyAttribute, 'data-layout-mode', layoutMode)
      sessionStorage.setItem('layoutMode', layoutMode)
    }
  } catch (error) {}
}

/**
 * Watchers
 */
export function* watchChangelayoutMode() {
  yield takeEvery(changeLayoutMode().type, changelayoutMode)
}

function* LayoutSaga() {
  yield all([fork(watchChangelayoutMode)])
}

export default LayoutSaga

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    notifications: [],
    isLoading: false,
    isClickedLoading: false,
    error: null
  }
  const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {

        getNotifications(state){
            state.isLoading = true
        }, 
        getNotificationsSaga(state, action){
            state.isLoading = false
            state.notifications = action.payload
        }, 
        handleIsClicked(state){
          state.isClickedLoading = true
        }, 
        handlIsClickedSaga(state, action){
          state.isClickedLoading = false
          state.notifications = state.notifications.map(not=>{
            if(not.id===action.payload.id){
              not.isClicked = 1
              return not
            }else{
              return not
            }
          })
          
        }, 
        handleNewNotification(state, action){
          state.notifications = action.payload
        }

    },
  })

export const {getNotifications, getNotificationsSaga, handleIsClicked, handlIsClickedSaga, handleNewNotification} = notificationSlice.actions
export default notificationSlice.reducer
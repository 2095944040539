import React, { Suspense } from 'react'
import { BrowserRouter, Routes as ReactRoutes, Route } from 'react-router-dom'
import { connect } from 'react-redux'

//import routes
import { authProtectedRoutes, publicRoutes, adminProtectedRoutes } from './routes'
// import { AuthProtectedRoutes } from './authProtectedRoutes'

const Routes = (props) => {

  return (
    <BrowserRouter>
      <React.Fragment>
        <Suspense fallback={<div></div>}>
          <ReactRoutes>
            <>
              { props?.user ? (
                <>
                  {
                    props?.user?.isAdmin === 1 || props?.user?.isSupperAdmin === 1 ? <>
                      {
                        adminProtectedRoutes.map((route) => (
                          <Route
                            element={route.component}
                            path={route.path}
                            key={route.path}
                          />
                        ))
                      }
                    </> : null
                  }
                  {authProtectedRoutes.map((route) => (
                    <Route
                      element={route.component}
                      path={route.path}
                      key={route.path}
                    />
                  ))}
                </>
              ) : (
                <>
                  {publicRoutes.map((route) => (
                    <Route
                      element={route.component}
                      path={route.path}
                      key={route.path}
                    />
                  ))}
                </>
              )}
            </>
          </ReactRoutes>
        </Suspense>
      </React.Fragment>
    </BrowserRouter>
  )
}

// export default Routes;
const mapStateToProps = (state) => {
  return {
    ...state.Auth,
  }
}
export default connect(mapStateToProps, {})(Routes)

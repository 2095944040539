import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activeSetting: 'locations',
  isListDataLoading: true, 
  error: null
}
const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    setActiveSetting(state, action) {
      state.activeSetting = action.payload.setting
    },
    fetchInitialData(state){
      state.isListDataLoading = true
    },
    disableLoadingSaga(state) {
      state.isListDataLoading = false
    }
  },
})

export const { setActiveSetting, fetchInitialData, disableLoadingSaga } = settingSlice.actions
export default settingSlice.reducer

import { all, put, takeEvery } from 'redux-saga/effects';
import { APIClient } from '../../helpers/apiClient';
import { 
    addLocation, 
    addLocationSaga,
    editLocation, 
    editLocationSaga,
    removeLocation,
    removeLocationSaga
} from './locationSlice';

const api = new APIClient();

// worker sagas
function* processAddLocation ({ payload }){
    try {
        const location = yield api.create('locations', payload.location)

        yield put(addLocationSaga({ location }))

    } catch (er) {
    }
}

function* processEditLocation ({ payload }){
    try {

        const location = yield api.put(`locations/${payload.location.id}`, payload.location);

        yield put(editLocationSaga({ location }));

    } catch (er){
    }
}

function* processRemoveLocation ({ payload }){
    try {
        yield api.delete(`locations/${payload.locationId}`);

        yield put(removeLocationSaga(payload))

    } catch (er){
    }
}


// watcher sagas
export function* watchProcesses() {
    yield takeEvery(addLocation().type, processAddLocation)
    yield takeEvery(removeLocation().type, processRemoveLocation)
    yield takeEvery(editLocation().type, processEditLocation)
}


function* locationSaga(){
    yield all([ watchProcesses() ])
}

export default locationSaga
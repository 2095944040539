import { createSlice } from '@reduxjs/toolkit'
import { getLoggedInUser,  } from '../../helpers/authUtils'

const initialState = { 
  loading: false, 
  user: getLoggedInUser(),
  error: null 
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginUser(state, action) {
      state.loading = true
      //return action
    },
    loginUserSuccess(state, action) {
      state.user = action.payload
      state.loading = false
      state.error = null
    },
    apiError(state, action) {
      state.loading = false
      state.error = action.payload
      
    },
    logoutUser(state, action) {
      state.loading = false
      state.user = null
      //return action not needed as we are now using logoutRedirect.js 
      //return action
    }
  },
})

export const { loginUser, loginUserSuccess, apiError, logoutUser } =
  authSlice.actions

export default authSlice.reducer
